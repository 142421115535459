import { globalProps } from './utils/globalProps';
import Hotjar from './utils/hotjar';

const { user, account } = globalProps;

if (user) {
  Hotjar.identify({
    is_credit_based: user.isCreditBased,
  });

  if (user.isCreditBased) {
    Hotjar.event('credit_based_trigger');
  }

  if (!account) {
    Hotjar.event('user_without_account');
  }
}
