import toOptionList from '@/utils/toOptionList';

export type SocialLoginProviderKeys = keyof typeof SocialLoginProviders;
export enum SocialLoginProviders {
  github = 'GitHub',
  bitbucket = 'Bitbucket',
  gitlab = 'GitLab',
  google = 'Google',
  saml = 'SSO',
}

export const HEADER_HEIGHT = 48;
export const NAV_WIDTH = 72;
export const NAV_WIDTH_OPEN = 264;

export const STATUS_PAGE_ID = '1q8r4n02vb2k';

export const ListOfCompetitors = toOptionList([
  'CircleCI',
  'GitHub Actions',
  'Buildkite',
  'Jenkins',
  'GitLab',
  'Other',
]);

export const BANNER_HEIGHT = 50;
