import { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { Box, Button, Input, Link } from '@bitrise/bitkit';
import { RevealablePasswordInput } from '@/components/RevealablePasswordInput/RevealablePasswordInput';
import useNotification from '@/hooks/useNotification';
import { navigateToUrl } from '@/utils/navigation';
import { getStandardErrorMessage } from '@/utils/errorResponseParser';
import { getDisplayableError } from '@/utils/formValidators/commonErrors';
import useSignIn, { SignInErrorResponse, SignInRequest } from '../hooks/useSignIn';

const InvalidCredentialsNotification = (): JSX.Element => (
  <>
    The username or password you entered is incorrect. Did you{' '}
    <Link href="/users/forgot-password" isUnderlined>
      forget your password
    </Link>
    ?
  </>
);

const getCustomErrorMessage = (error: AxiosError<SignInErrorResponse>): ReactNode => {
  const status = error.response?.status;

  if (status === 401) {
    return <InvalidCredentialsNotification />;
  }

  if (status === 405) {
    return 'Your account has SSO login enforced by your organization. You can only log in via SAML SSO, please use SAML SSO to log in.';
  }

  return getStandardErrorMessage(error);
};

type FormDataType = SignInRequest['user'];

const SignInForm = (): JSX.Element => {
  const { setErrorNotification, removeNotification } = useNotification();
  const { mutate: submitSignIn, isPending, isSuccess } = useSignIn();
  const { register, handleSubmit, formState, watch } = useForm<FormDataType>({
    mode: 'onChange',
    defaultValues: {
      login: '',
    },
  });

  const { errors, isValid } = formState;
  const { ref: loginRef, ...loginProps } = register('login', { required: 'Email or username is required.' });
  const { ref: passwordRef, ...passwordProps } = register('password', { required: 'Password is required.' });
  const login = watch('login');

  const onSubmit = handleSubmit(async (values) => {
    datadogRum.addAction('submit');

    removeNotification();

    const { password } = values;

    submitSignIn({ user: { login, password, rememberMe: true } } as SignInRequest, {
      onSuccess: (response) => {
        navigateToUrl(response.headers.location || '/');
      },
      onError: (error) => {
        setErrorNotification(getCustomErrorMessage(error));
      },
    });
  });

  const isSubmitDisabled = !isValid || isPending || isSuccess;

  return (
    <Box as="form" onSubmit={onSubmit} display="flex" flexDirection="column" gap="16" noValidate>
      <Input
        label="Email or username"
        placeholder="Email or username"
        autoFocus
        inputRef={loginRef}
        errorText={getDisplayableError(errors.login?.message, 'Email or username')}
        isRequired
        {...loginProps}
      />
      <RevealablePasswordInput
        inputRef={passwordRef}
        isInvalid={!!errors.password}
        label="Password"
        placeholder="Password"
        errorText={getDisplayableError(errors.password?.message, 'Password')}
        isRequired
        {...passwordProps}
      />

      <Button type="submit" isLoading={isPending} isDisabled={isSubmitDisabled} width="100%">
        Log in
      </Button>
    </Box>
  );
};

export default SignInForm;
