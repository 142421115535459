import { useEffect } from 'react';
import { NotificationProps } from '@bitrise/bitkit';

import { SocialLoginProviders, SocialLoginProviderKeys } from '@/constants';
import useNotification from '@/hooks/useNotification';
import { getQueryParam } from '@/utils/queryParams';

const useSignInFlashMessage = () => {
  const { setErrorNotification, setWarningNotification, setInfoNotification } = useNotification();
  const flashKey = getQueryParam('flash');
  const email = getQueryParam('email');
  const provider = getQueryParam('provider');
  const errorMessageDebugInfo = getQueryParam('error_message_debug_info');

  useEffect(() => {
    let message = '';
    let level: NotificationProps['status'] = 'error';

    switch (flashKey) {
      case 'confirmed':
        message = 'You have successfully confirmed your email address';
        level = 'info';
        break;
      case 'unconfirmed':
        message =
          'Please confirm your email address to be able to log in with SSO. You can do so by clicking on the link in the confirmation email we sent when you created your account.';
        break;
      case 'invalid-confirmation':
        message = 'This is an invalid URL or this account is already confirmed. Please try logging in.';
        break;
      case 'sso-email-taken':
        if (email && provider) {
          setWarningNotification(
            `Email ${email} already found - log in with your email and link your ${
              SocialLoginProviders[provider as SocialLoginProviderKeys] || provider
            } account from your profile page.`,
          );
        }
        break;
      case 'enable-sso-login':
        message =
          'You have to be logged in the first time you authorize your organization to log you in with SSO. Please log in with your original credentials first and then visit the SSO login URL.';
        break;
      case 'no-saml-sso-identity':
        message = 'There is no SAML SSO identity for this user.';
        break;
      case 'other-saml-sso-identity':
        message = 'You have enabled SAML SSO with another organization.';
        break;
      case 'sso-identity-already-in-use':
        message = `${errorMessageDebugInfo}`;

        break;
      case 'sso-error':
        message =
          'Something went wrong while trying to log in via SAML SSO. Please try to log in again or contact your SAML SSO admin for further information.';

        if (errorMessageDebugInfo) {
          message += ` ${errorMessageDebugInfo}`;
        }

        break;
      default:
        break;
    }

    if (message !== '') {
      switch (level) {
        case 'info':
          setInfoNotification(message);

          break;
        case 'error':
          setErrorNotification(message);

          break;
        default:
          break;
      }
    }
  }, [
    flashKey,
    email,
    provider,
    errorMessageDebugInfo,
    setErrorNotification,
    setWarningNotification,
    setInfoNotification,
  ]);
};

export default useSignInFlashMessage;
