import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { monolithApi } from '@/utils/api';
import { ErrorResponse } from '@/models/Error';

export type SignInRequest = {
  user: {
    login: string;
    password: string;
    rememberMe?: boolean;
  };
};
type SignInFieldNames = keyof SignInRequest['user'];
export type SignInErrorResponse = ErrorResponse<SignInFieldNames>;

export type UseSignInResult = UseMutationResult<AxiosResponse, AxiosError<SignInErrorResponse>, SignInRequest>;

export default (): UseSignInResult => {
  return useMutation<AxiosResponse, AxiosError<SignInErrorResponse>, SignInRequest>({
    mutationFn: (data) => monolithApi.post('/users/sign_in.json', data),
  });
};
