import { forwardRef, LegacyRef } from 'react';
import { Box } from '@bitrise/bitkit';

const TooltipPortal = forwardRef((_props, ref) => (
  <Box
    width="100%"
    position="sticky"
    top="0"
    overflow="visible"
    zIndex="5500"
    transform="translateZ(0)"
    ref={ref as LegacyRef<HTMLDivElement>}
  />
));

export default TooltipPortal;
