import 'regenerator-runtime/runtime';
import '../../datadog';
import '../../hotjar-init';

import React, { createRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from '@bitrise/bitkit';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NotificationProvider } from '@/hooks/useNotification';
import Status500ErrorPageContent from '@/pages/error/ErrorPage/Status500ErrorPageContent';
import { queryClient } from '@/utils/query';
import { globalProps } from '@/utils/globalProps';
import { dataDogErrorHandler } from '@/utils/dataDogErrorHandler';
import { GlobalPropsContext } from './GlobalPropsContext';
import TooltipPortal from './TooltipPortal';

export const Layout = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const toastPortalRef = createRef<HTMLDivElement>();
  return (
    <Provider toastOptions={{ offsetTop: '72px', offsetRight: '32px', portalRef: toastPortalRef }}>
      <TooltipPortal ref={toastPortalRef} />
      <ErrorBoundary FallbackComponent={Status500ErrorPageContent} onError={dataDogErrorHandler}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <NotificationProvider>
            <GlobalPropsContext.Provider value={globalProps}>{children}</GlobalPropsContext.Provider>
          </NotificationProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </Provider>
  );
};

export const withLayout =
  <P extends object>(WrappedComponent: React.ComponentType<P>): React.ComponentType<P> =>
  (props) => (
    <Layout>
      <WrappedComponent {...props} />
    </Layout>
  );
