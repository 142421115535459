import { Box, Image, Link, Text, TextProps } from '@bitrise/bitkit';

const CheckStatusPageAndContactSupportDetails = (props: TextProps): JSX.Element => (
  <Text size="2" textColor="neutral.30" {...props}>
    You can check the{' '}
    <Link colorScheme="purple" href="https://status.bitrise.io/">
      status of our systems
    </Link>
    . If the problem persists,{' '}
    <Link colorScheme="purple" href="https://bitrise.zendesk.com/hc/en-us">
      contact support
    </Link>
    .
  </Text>
);

const Status500ErrorPageContent = (): JSX.Element => (
  <Box
    display="flex"
    height="100vh"
    width="100vw"
    flexDirection="column"
    justifyContent="center"
    textAlign="center"
    padding={[16, 32]}
  >
    <Text as="h1" size="7" fontWeight="bold">
      500
    </Text>
    <Text as="h2" size="2" textTransform="uppercase" fontWeight="bold" marginBottom="24">
      Something went wrong
    </Text>

    <Text letterSpacing="8" size="4">
      Everything is under control, we will be back soon. Until then keep refreshing your browser.
    </Text>

    <Image
      marginY="64"
      marginX="auto"
      maxWidth="90vw"
      src="https://bitrise-bitkit.s3.us-east-2.amazonaws.com/assets/Status500.svg"
      alt="Bitbot"
    />
    <CheckStatusPageAndContactSupportDetails />
  </Box>
);

export { CheckStatusPageAndContactSupportDetails };
export default Status500ErrorPageContent;
