import React, { createContext, useCallback, useContext, useState } from 'react';
import { NotificationProps } from '@bitrise/bitkit';
import { isNotificationLike, Notification, NotificationArg, NotificationContextValue } from '@/models/Notification';

const NotificationContext = createContext<NotificationContextValue | undefined>(undefined);

const NotificationProvider = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  const [notification, setNotification] = useState<Notification | null>(null);
  const setNotificationAs = useCallback(
    (status: NotificationProps['status'], arg: NotificationArg) => {
      const { message, iconName = undefined } = isNotificationLike(arg) ? arg : { message: arg };
      setNotification({ status, iconName, message });
    },
    [setNotification],
  );
  const setInfoNotification = useCallback(
    (arg: NotificationArg) => setNotificationAs('info', arg),
    [setNotificationAs],
  );
  const setWarningNotification = useCallback(
    (arg: NotificationArg) => setNotificationAs('warning', arg),
    [setNotificationAs],
  );
  const setErrorNotification = useCallback(
    (arg: NotificationArg) => setNotificationAs('error', arg),
    [setNotificationAs],
  );
  const setSuccessNotification = useCallback(
    (arg: NotificationArg) => setNotificationAs('success', arg),
    [setNotificationAs],
  );
  const setProgressNotification = useCallback(
    (arg: NotificationArg) => setNotificationAs('progress', arg),
    [setNotificationAs],
  );
  const removeNotification = useCallback(() => setNotification(null), [setNotification]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    notification,
    setInfoNotification,
    setWarningNotification,
    setErrorNotification,
    setSuccessNotification,
    setProgressNotification,
    removeNotification,
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

const useNotification = (): NotificationContextValue => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }

  return context;
};

export { NotificationProvider };
export default useNotification;
