export default class Hotjar {
  static identify(userAttributes: any): void {
    this.getHotjarApi()('identify', null, userAttributes);
  }

  // Use the new event API instead of the trigger and tagRecording!
  // https://help.hotjar.com/hc/en-us/articles/4405109971095
  static event(name: string): void {
    const isMatching = /^[-a-zA-Z0-9_]{1,749}$/.test(name);
    if (isMatching) {
      this.getHotjarApi()('event', name);
    } else {
      console.error(`Invalid event: '${name}'!\nOnly a-z, A-Z, 0-9, - or _ chars, and max 750 chars!`);
    }
  }

  private static getHotjarApi(): HotjarApi {
    // Hotjar is initialized in GTM. We need to make sure that it has already been initialized before
    // adding the user attributes.
    // https://help.hotjar.com/hc/en-us/articles/360061197694-User-Attributes-FAQs#can-identify-calls-be-made-using-gtm
    window.hj =
      window.hj ||
      (function () {
        // eslint-disable-next-line prefer-rest-params
        ((window.hj as any).q = (window.hj as any).q || []).push(arguments);
      } as HotjarApi);

    return window.hj as HotjarApi;
  }
}
