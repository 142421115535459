import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

export type TypeValue = {
  set?: { type: string; value: string }[];
  unset?: string[];
  append?: { type: string; values: string[] };
};

type StateAction = 'push' | 'replace';

export const QUERY_PARAMS_CHANGED = 'queryParamsChanged';

const buildQueryString = (query: any) => new URLSearchParams(pickBy(query, identity)).toString();

const isBooleanValue = (value: string | null): boolean => {
  return value !== null && value.trim() !== '' && (value.toLowerCase() === 'true' || value.toLowerCase() === 'false');
};

const isNumberValue = (value: string | null): value is string => {
  return value !== null && !Number.isNaN(Number(value));
};

const getQueryParam = <T = string>(name: string, defaultValue?: T): T | null => {
  const params = typeof window === 'undefined' ? new URLSearchParams() : new URLSearchParams(window.location.search);
  const fallbackValue = defaultValue !== undefined ? defaultValue : null;
  return (params.get(name) as T) || fallbackValue;
};

const getQueryParamBoolean = (name: string, defaultValue?: boolean): boolean | null => {
  const value = getQueryParam(name);
  const fallbackValue = defaultValue !== undefined ? defaultValue : null;
  return isBooleanValue(value) ? value?.toLowerCase() === 'true' : fallbackValue;
};

const getQueryParamNumber = (name: string, defaultValue?: number): number | null => {
  const value = getQueryParam(name);
  const fallbackValue = defaultValue !== undefined ? defaultValue : null;
  return value !== undefined && isNumberValue(value) ? Number(value) : fallbackValue;
};

export const updateQueryString = (query: URLSearchParams, stateAction?: StateAction) => {
  const queryString = query.toString();
  let url = `${window.location.origin}${window.location.pathname}`;
  if (queryString) {
    url += `?${query.toString()}`;
  }

  if (stateAction === 'replace') {
    window.history.replaceState({}, '', url);
  } else {
    window.history.pushState({}, '', url);
  }
  window.postMessage({ type: QUERY_PARAMS_CHANGED }, window.location.origin);
};

export const filterQueryParams = (keep: string[] = []) => {
  const searchParams = new URLSearchParams(window.location.search);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore next-line
  Array.from(searchParams.entries()).forEach(([key]) => {
    if (!keep.includes(key)) {
      searchParams.delete(key);
    }
  });
  return searchParams;
};

export const persistQueryParams = (path: string, persistedQueryParams: string[] = []) => {
  if (persistedQueryParams.length === 0) {
    return path;
  }

  const searchParams = filterQueryParams(persistedQueryParams);
  const queryString = searchParams.toString();
  if (queryString === '') {
    return path;
  }

  const hasQueryParam = path.includes('?');
  return `${path}${hasQueryParam ? '&' : '?'}${queryString}`;
};

export const mergeQueryParam = (type: string, value: string | number | null) => {
  if (value || value === 0) {
    mergeQueryParams({ set: [{ type, value: String(value) }] });
  } else {
    mergeQueryParams({ unset: [type] });
  }
};

export const mergeQueryParams = ({ set = [], unset = [], append }: TypeValue, stateAction?: StateAction) => {
  const query = new URLSearchParams(window.location.search);
  set.forEach(({ type, value }) => {
    query.set(type, value);
  });
  unset.forEach((type) => {
    query.delete(type);
  });
  append?.values.forEach((v) => {
    query.append(append.type, v);
  });
  updateQueryString(query, stateAction);
};

export const updateQueryParam = (paramKey: string, toUpdate: string[], stateAction?: StateAction) => {
  const query = new URLSearchParams(window.location.search);
  query.delete(paramKey);
  toUpdate.forEach((filter) => {
    query.append(paramKey, filter);
  });
  updateQueryString(query, stateAction);
};

export { getQueryParam, getQueryParamBoolean, getQueryParamNumber, buildQueryString, isNumberValue };
