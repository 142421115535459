import { useState } from 'react';
import { Box, Link, Text } from '@bitrise/bitkit';

import PublicPage from '@/components/page/PublicPage';
import PageContent from '@/components/page/PageContent/PageContent';
import PageTitle, { PageTitleHeading, PageTitleSubheading } from '@/components/page/PageTitle/PageTitle';
import NotificationSlot from '@/components/NotificationSlot/NotificationSlot';
import BackButton from '@/components/buttons/BackButton/BackButton';
import LoginButtonGroup from '@/components/buttons/LoginButtonGroup/LoginButtonGroup';
import LoginCard from '@/components/LoginCard/LoginCard';
import StaticHeader from '@/components/page/PageHeader/StaticHeader';
import SignInForm from './components/SignInForm';
import useSignInFlashMessage from './hooks/useSignInFlashMessage';

const SignInPage = () => {
  const [formOpened, isFormOpened] = useState(false);

  useSignInFlashMessage();

  return (
    <PublicPage
      bgGradient="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, purple.95 100%), neutral.100"
      h="calc(100vh)"
    >
      <StaticHeader>
        <Link colorScheme="purple" href="/users/sign_up">
          Sign up &rarr;
        </Link>
      </StaticHeader>
      <PageTitle textAlign="center">
        <PageTitleHeading size="6">Log in to your account</PageTitleHeading>
        <PageTitleSubheading size="3">Welcome back!</PageTitleSubheading>
      </PageTitle>
      <PageContent paddingTop="0">
        <NotificationSlot />
        <LoginCard>
          {formOpened && (
            <Box display="flex" flexDirection="column" gap="24" maxWidth="368">
              <BackButton onBack={() => isFormOpened(!formOpened)} text="Log in another way" />
              <Text as="h2" fontWeight="bold" size="4">
                Log in with Email
              </Text>
              <SignInForm />
            </Box>
          )}
          {!formOpened && <LoginButtonGroup onEmailSelect={() => isFormOpened(!formOpened)} signUpMode={false} />}
        </LoginCard>
        <Link colorScheme="purple" href="/users/forgot-password" textAlign="center">
          Forgot your password?
        </Link>
      </PageContent>
    </PublicPage>
  );
};

export default SignInPage;
