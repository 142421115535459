import { ReactNode } from 'react';
import { NotificationProps } from '@bitrise/bitkit';

export type Notification = {
  message: ReactNode;
  status: NotificationProps['status'];
  iconName?: NotificationProps['iconName'];
};

export type NotificationLike = Omit<Notification, 'status'>;
export type NotificationArg = ReactNode | NotificationLike;

export const isNotificationLike = (arg: NotificationArg): arg is NotificationLike => {
  const notification = arg as NotificationLike;
  return Boolean(notification && notification.message);
};

export type NotificationContextValue = {
  notification: Notification | null;
  setInfoNotification: (notification: NotificationArg) => void;
  setWarningNotification: (notification: NotificationArg) => void;
  setErrorNotification: (notification: NotificationArg) => void;
  setSuccessNotification: (notification: NotificationArg) => void;
  setProgressNotification: (notification: NotificationArg) => void;
  removeNotification: () => void;
};
